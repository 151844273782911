<template>
  <div class="lista-canciones-scroll">
    <TopHeaderListadoCanciones :is_filter_data="$store.getters.showFilteredData"/>
    <div class="titulos-canciones-wrapper" v-if="!$store.getters.showFilteredData">
      <div
        v-for="(cancion, i) in titulosPrincipales"
        :key="cancion.data.id"
        :class="{'info-cancion' : cancion.show_info}"
      >
        <div
          class="titulos-principles-title mt-3 pl-4 pb-4"
          :class="[getFactValue(cancion.data.fact), {'pt-show-info': cancion.show_info}]"
        >
          <v-layout row class="titulos-principales-layout">
            <v-flex grow class="open-cancion-info pt-4">
              <div
                flat
                @click="showCancionInfo(cancion)"
                :class="{'black--text':isBlack(cancion),
                'white--text': !isBlack(cancion)}"
                class="pa-0 mx-0 title-font titulos-principales text-uppercase letter-space"
              >{{ cancion.data.songtitle }}</div>
            </v-flex>
            <v-btn
              icon
              v-if="cancion.show_info"
              class="close-info-btn"
              :class="{'black--text': isBlack(cancion) && cancion.show_info}"
              @click="closeBoxInfo(cancion)"
            >
              <v-icon medium :color="isBlack(cancion) ? 'black' : 'white'">close</v-icon>
            </v-btn>
          </v-layout>
          <v-expand-transition>
            <CancionInfo
              class="pr-4"
              :black="isBlack(cancion)"
              :band="cancion.data.band"
              :year="cancion.data.year"
              :songStartTime="cancion.data.start_time"
              :songEndTime="cancion.data.end_time"
              text
              :fact_description="cancion.data.fact_description"
              v-if="cancion.show_info"
              :song_title="cancion.data.songtitle"
              :song_id="cancion.data.id"
              :youtube="cancion.data.youtube"
            ></CancionInfo>
          </v-expand-transition>
        </div>
        <!--<div id="titulo-secundarios" v-for="(secundario, e) in titulos_secundarios.slice(0, 4)" :key="e">-->
        <div class="white mt-3" v-for="(element, e) in getElement(i)" :key="e">
          <div v-for="(secundario, n) in element" :key="secundario.data.id">
            <!-- <div class="titulos-secundarios-wrapper lista-canciones-box"> -->
            <div class="titulos-secundarios-title ml-4 py-2">
              <v-layout row align-center class="titulos-secundarios-inner-box">
                <font-awesome-icon
                  icon="circle"
                  class="titulo-secundario-icon"
                  :class="{'titulo-secundario-icon-open-box': secundario.show_info}"
                />
                <v-flex
                  @click="showCancionInfo(secundario)"
                  class="ml-1 secondary-song mx-0 pl-0 titulos-secundarios"
                  :class="{'black white--text' : secundario.show_info}"
                >
                  <div
                    class="ml-1 titulos-canciones-fs title-font titulos-secundarios text-uppercase letter-space titulo-secundario-title"
                  >{{ secundario.data.songtitle }}</div>
                </v-flex>
                <v-btn
                  icon
                  light
                  v-if="secundario.show_info"
                  class="close-ts-info-btn my-0"
                  @click="closeBoxInfo(secundario)"
                >
                  <v-icon medium>close</v-icon>
                </v-btn>
              </v-layout>
              <v-expand-transition>
                <CancionInfo
                  black
                  class="pl-4 pt-2"
                  :band="secundario.data.band"
                  :year="secundario.data.year"
                  :songStartTime="secundario.data.start_time"
                  :songEndTime="secundario.data.end_time"
                  v-if="secundario.show_info"
                  bottom_line
                  :song_title="secundario.data.songtitle"
                  :song_id="secundario.data.id"
                  :youtube="secundario.data.youtube"
                ></CancionInfo>
              </v-expand-transition>
              <v-spacer class="ts-bottom-spacer" v-if="n == element.length - 1"></v-spacer>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <CancionesFiltros
      v-if="$store.getters.showFilteredData"
      :filtered_data="$store.getters.getFilteredData"
      v-on:on-show-info="showCancionInfo"
    ></CancionesFiltros>
  </div>
</template>

<script>
import CancionInfo from "./CancionInfo";
import TopHeaderListadoCanciones from "../Canciones/TopHeaderListadoCanciones";
import CancionesFiltros from "../Canciones/CancionesFiltros";

export default {
  name: "ListadoCanciones",
  components: {
    CancionInfo,
    TopHeaderListadoCanciones,
    CancionesFiltros
  },
  props: {
    years: null,
    current_year: {
      type: String,
      default: ""
    },
    titulosPrincipales: Array,
    titulosSecundarios: Array,
    view_year: Boolean
  },
  mounted() {
    this.viewYear();
  },
  data() {
    return {
      canciones: null,
      show_info_cancion: false,
      cancion_name: "",
      titulos_principales: null,
      titulos_secundarios: null,
      splice_range: 0,
      splice_titulos_secundarios: null,
      none_filter_white: null,
      filter_data: false,
      data_by_filter: null,
      filter_list: null
    };
  },
  computed: {
    spliceStart: {
      get() {
        return this.splice_start;
      },
      set(value) {
        this.splice_start = value;
      }
    },
    spliceTitulosSecundarios: {
      get() {
        return this.splice_titulos_secundarios;
      },
      set(value) {
        this.splice_titulos_secundarios = value;
      }
    },
    nonFilterWhite: {
      get() {
        return this.none_filter_white;
      },
      set(value) {
        this.none_filter_white = value;
      }
    },
    filterData: {
      get() {
        return this.filter_data;
      },
      set(value) {
        this.filter_data = value;
      }
    },
    dataByFilter: {
      get() {
        return this.data_by_filter;
      },
      set(value) {
        this.data_by_filter = value;
      }
    },
    filterList: {
      get() {
        return this.filter_list;
      },
      set(value) {
        this.filter_list = value;
      }
    }
  },
  methods: {
    isBlack(cancion) {
      const fact = this.getFactValue(cancion.data.fact);
      return fact === "lenguaje-bkg" || fact === "polemica-bkg";
    },
    closeBoxInfo(data) {
      data.show_info = false;
    },
    showCancionInfo: function(data) {
      data.show_info = true;
      this.$store.commit("youtubeLink", data.data.youtube);
    },
    getElement(index) {
      return this.$props.titulosSecundarios.filter(function(item, i) {
        if (index === i) return item;
      });
    },
    getFactValue(fact) {
      switch (fact[0]) {
        case "Música":
          return "musica-bkg";
        case "Baile":
          return "baile-bkg";
        case "Polémica":
          return "polemica-bkg";
        case "Social":
          return "social-bkg";
        case "Lenguaje":
          return "lenguaje-bkg";
        default:
          this.none_filter_white = "non-filter-white";
          return "non-filter";
      }
    },
    viewYear() {
      if (this.view_year) {
        this.$store.commit("setViewYear", true);
      } else this.$store.commit("setViewYear", false);
    }
  }
};
</script>
<style>
.secondary-song {
  font-size: 15px;
}
.polemica-bkg {
  background-color: rgba(13, 224, 193, 0.95);
  color: white;
}

.baile-bkg {
  background-color: rgba(246, 0, 193, 0.95);
  color: white;
}

.lenguaje-bkg {
  background-color: rgba(229, 220, 22, 0.95);
  color: black !important;
}

.musica-bkg {
  background-color: rgba(238, 10, 27, 0.95);
  color: white;
}

.social-bkg {
  background-color: rgba(125, 14, 242, 0.95);
  color: white;
}

.non-filter {
  background: rgba(5, 5, 5, 0.7);
  color: white;
}

/* .close-info-btn {
  right: 0;
  left: 60vw;
  color: white !important;
} */

/* .open-cancion-info {
  text-decoration: none;
  color: black;
} */

/* .link-text-color {
  color: white;
} */

.ts-bottom-spacer {
  padding-top: 4vw;
}

/* .close-ts-info-btn {
  margin-top: -2vw;
  margin-left: 64vw;
} */

/*.close-ts-info-btn.active.focus,*/
/*.close-ts-info-btn.active:focus,*/
/*.close-ts-info-btn.focus,*/
/*.close-ts-info-btn.focus:active,*/
/*.close-ts-info-btn:active:focus,*/
/*.close-ts-info-btn:focus {*/
/*-webkit-box-shadow: none !important;*/
/*box-shadow: none  !important;*/
/*}*/

/* .titulo-secundario-bkg {
  background-color: black;
  color: white;
  width: 100vw;
  margin-bottom: 2vw;
} */

/* .titulos-secundarios-inner-box {
  padding-top: 4vw;
  padding-bottom: 1vw;
} */

/* .lista-canciones-scroll {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  scrollbar-color: gray lightgray;
} */

/* .lista-canciones-scroll::-webkit-scrollbar {
  display: none;
} */

/* .lista-canciones {
  max-width: 85vw;
  overflow: hidden;
} */

/* .lista-canciones-box {
  background-color: white;
  color: black;
} */

.titulos-principales-layout {
  overflow: hidden;
}

.titulos-principales {
  font-size: 15px;
  /* color: white; */
  /* margin-left: 1vw; */
}

/* .titulos-principal-black {
  color: black !important;
} */

/* .titulos-canciones-fs {
  font-size: 3.5vw;
} */

/* .titulos-principles-title {
  margin: 3vw 0;
  padding: 0 5vw;
} */

.titulos-secundarios-title {
  border-left: solid 2px;
  /* margin-left: 8vw; */
}

/* .titulo-secundario-title {
  overflow: hidden;
} */

/* .titulo-secundario-title {
  margin-left: 3vw;
} */

.titulo-secundario-icon {
  font-size: xx-small;
  color: black;
  margin-bottom: 2px;
  margin-left: -5px;
}

.letter-space {
  letter-spacing: 2px;
}

/* .pt-show-info {
  padding-top: 2vh;
} */

@media only screen and (min-device-width: 0) and (max-device-width: 320px) {
  /* .lista-canciones-scroll {
    overflow-x: hidden;
  } */
}

@media (min-width: 400px) and (max-width: 740px) {
  /* .lista-canciones {
    max-width: 92vw !important;
  } */

  /* .titulos-principales {
    font-size: 3vw;
  } */

  /* .titulos-canciones-fs {
    font-size: 2.5vw;
  } */

  /* .lista-canciones-top-title {
    font-size: 5vw !important;
  }

  .black-spacer {
    margin-top: 5vh;
  } */
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  /* .titulos-principales,
  .titulos-canciones-fs {
    font-size: 14px;
  } */

  /* .titulos-principles-title {
    margin: 2vh 0;
    padding: 1vh 2vw;
  } */

  /* .titulos-principales-layout {
    margin-left: 2vw;
  } */

  /* .titulo-secundario-bkg {
    height: 30px;
    margin-bottom: 1vw;
  } */

  /* .titulo-secundario-title {
    margin-left: 1vw;
  } */

  /* .titulos-secundarios-title {
    margin-left: 5.1vw;
  } */

  /* .titulos-secundarios-inner-box, */
  /* .ts-bottom-spacer {
    padding-top: 1.7vw;
  } */

  /* .close-info-btn {
    left: 31.4vw;
  } */

  /* .close-ts-info-btn {
    margin-left: 32.8vw;
    width: 25px;
    height: 25px;
  } */

  /* .link-text-color {
    position: relative;
    top: 4px;
  } */

  /* .titulo-secundario-icon-open-box {
    color: white;
    margin-left: -7px;
  } */
}
</style>

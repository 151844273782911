<template>
  <div>
    <div class="canciones-home" ref="yearSlider">
      <v-layout
        id="year-title-wrapper"
        class="title-font font-weight-bold year-title canciones-title-style py-5"
        justify-center
        align-center
        v-touch="{left: increaseYear, right: decreaseYear}"
      >
        <v-btn icon @click="decreaseYear" :disabled="year === '2010'">
          <v-icon
            medium
            color="#ff0037"
            v-if="year !== '2010'"
            class="arrow-indicator"
          >arrow_back_ios</v-icon>
        </v-btn>
        <router-link :to="{name: 'song-list', params: {current_year: year}}">{{year}}</router-link>
        <v-btn icon @click="increaseYear" :disabled="year === '2018'">
          <v-icon
            medium
            color="#ff0037"
            class="arrow-indicator"
            v-if="year !== '2018'"
          >arrow_forward_ios</v-icon>
        </v-btn>
      </v-layout>
    </div>
    <v-layout>
      <v-flex xs10 class="black white--text pa-4 text-body" v-html="description"></v-flex>
      <!-- <v-flex> -->
      <v-layout column>
        <v-flex>
          <sidebar view_actions class="black" />
        </v-flex>
        <!-- <v-flex></v-flex> -->
        <v-flex shrink class="black">
          <router-link :to="{name: 'song-list', params: {current_year: year}}">
            <v-layout column justify-center style="height: 40px;">
              <v-icon color="white" class="go-to-canciones" medium>add</v-icon>
            </v-layout>
          </router-link>
        </v-flex>
      </v-layout>
      <!-- </v-flex> -->
    </v-layout>
  </div>
</template>

<script>
import CancionesSidebar from "../Canciones/CancionesSidebar";

export default {
  name: "",
  components: {
    sidebar: CancionesSidebar
  },
  data() {
    return {
      year: "2010",
    };
  },
  methods: {
    increaseYear() {
      this.year = +this.year + 1 + "";
    },
    decreaseYear() {
      this.year = +this.year - 1 + "";
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const { year } = to.params;
      if (year) {
        vm.year = year;
      }
      next();
    });
  },
  computed: {
    description() {
      return this.$store.getters.getYearData.find(d => d.year === this.year)
        .text;
    }
  }
};
</script>

<style lang="scss" scoped>
.canciones-home {
  background: url("/img/canciones/canciones-home-bkg.jpeg") no-repeat;
  background-size: cover;
  // width: 100vw;
  // height: 50vh;
}

.year-title {
  font-size: 80px;
}

.canciones-title-style {
  opacity: 0.7;
}

.canciones-title-style > a {
  color: #ff0037;
}

@keyframes arrow-jump {
  0% {
  }
  100% {
    transform: translateX(10px);
  }
}
.arrow-indicator {
  animation: arrow-jump 1s infinite;
}

.canciones-home-text-wrapper {
  background-color: black;
  color: white;
}
</style>
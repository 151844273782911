<template>
  <v-layout column class="canciones-desktop-container">
    <div class="canciones-desktop-header-container">
      <v-img src="/img/home/PORTADA-CANCIONES-DESKTOP.gif" class="gif-canciones">
        <!-- <v-responsive>
        <video preload="yes" autoplay loop muted playsinline class="main-video">
          <source src="/img/home/PORTADA-CANCIONES-DESKTOP.webm" type="video/webm" />
          <source src="/img/home/PORTADA-CANCIONES-DESKTOP.mp4" type="video/mp4" />
        </video>
        </v-responsive>-->
        <v-layout row fill-height align-end id="title">
          <v-flex offset-xs1>
            <div
              class="px-4 py-3 black text-uppercase white--text title-font title-text"
            >canciones + populares</div>
          </v-flex>
        </v-layout>
        <!-- <v-layout column class="canciones-desktop-header">
                  <v-layout row class="canciones-desktop-title-container text-uppercase">
                          <span class="font-weight-bold header-description-title header-font">canciones</span>
                          <span class="font-weight-bold header-description-title header-font">+populares</span>
                  </v-layout>
                  <v-divider class="canciones-header-divider"></v-divider>
        </v-layout>-->
      </v-img>
    </div>
    <v-layout
      row
      class="px-5 pb-5"
      id="canciones-container"
      :class="{'mt-on-modal': $store.getters.getShowFavoritosModal}"
    >
      <!-- <v-layout row class="canciones-year-info"> -->
      <v-flex xs6>
        <v-layout column fill-height class="white pb-5">
          <v-flex shrink>
            <v-layout row justify-center align-center class="year-slide">
              <v-btn flat color="grey" icon @click="prevYear" :disabled="year === '2010'">
                <v-icon medium>arrow_back_ios</v-icon>
              </v-btn>
              <span class="font-weight-bold title-font grey--text year-title">{{year}}</span>
              <v-btn flat color="grey" icon @click="nextYear" :disabled="year === '2018'">
                <v-icon medium>arrow_forward_ios</v-icon>
              </v-btn>
            </v-layout>
          </v-flex>
          <div id="year-description">
            <p class="text-body canciones-home-text px-5 pt-3" v-html="description"></p>
          </div>
        </v-layout>
      </v-flex>
      <!-- </v-layout> -->
      <v-layout column class="canciones-filters">
        <!-- <v-flex class="sidebar-spacer top-spacer-height"></v-flex> -->
        <CancionesSidebar view_filters class="canciones-filters-height"></CancionesSidebar>
        <!-- <v-flex class="sidebar-spacer bottom-spacer-height"></v-flex> -->
        <v-spacer class="white"></v-spacer>
      </v-layout>
      <v-flex xs6 id="canciones-info">
        <ListadoCanciones
          :titulos-principales="titulosPrincipales"
          :titulos-secundarios="titulosSecundarios"
        ></ListadoCanciones>
      </v-flex>
    </v-layout>
    <!-- <v-layout row class="comments-container-canciones-desktop">
          <ListaComentarios></ListaComentarios>
          <ComentariosForm></ComentariosForm>
      </v-layout>
    <AppFooter footer_canciones view_components footer></AppFooter>-->
  </v-layout>
</template>

<script>
import CancionesSidebar from "../../components/Canciones/CancionesSidebar";
import ListadoCanciones from "../../components/Canciones/ListadoCanciones";

export default {
  name: "CancionesDesktop",
  components: {
    CancionesSidebar,
    ListadoCanciones
  },
  props: {
    data: Array
  },
  data() {
    return {
      year: "",
      description: "",
      prev_pos: null,
      next_pos: null,
      titulosPrincipales: [],
      titulosSecundarios: []
    };
  },
  mounted() {
    for (let el of this.data) {
      if (el.year === "2010") {
        this.description = el.text;
        this.year = el.year;
        this.$store.commit("songYear", this.year);
        this.$store.commit("setViewYear", false);
        this.$store.dispatch("spliceTitulosSecundarios").then(() => {
          this.titulosPrincipales = this.$store.getters.titulosPrincipales;
          this.titulosSecundarios = this.$store.getters.getTitulosSecundariosPartitioned;
          this.next_pos = 1;
          this.prev_pos = 0;
        });
      }
    }
    // this.getSongOfYear();
  },
  methods: {
    nextYear() {
      let el = this.data[this.next_pos];
      this.year = el.year;
      this.$store.commit("songYear", this.year);
      this.removeFilters();
      // this.getSongOfYear();
      this.$store.dispatch("spliceTitulosSecundarios").then(() => {
        this.titulosPrincipales = this.$store.getters.titulosPrincipales;
        this.titulosSecundarios = this.$store.getters.getTitulosSecundariosPartitioned;
        this.description = el.text;
        this.prev_pos = this.next_pos;
        this.next_pos++;
      });
    },
    prevYear() {
      this.next_pos = this.prev_pos;
      this.prev_pos--;
      let el = this.data[this.prev_pos];
      this.year = el.year;
      this.$store.commit("songYear", this.year);
      this.removeFilters();
      // this.getSongOfYear();
      this.$store.dispatch("spliceTitulosSecundarios").then(() => {
        this.titulosPrincipales = this.$store.getters.titulosPrincipales;
        this.titulosSecundarios = this.$store.getters.getTitulosSecundariosPartitioned;
        this.description = el.text;
      });
    },
    removeFilters() {
      let el = this.$el.getElementsByClassName("filter-opacity");
      if (el.length != 0) {
        // this.$store.commit('setFilterOpacity', false);
        for (let item of el) {
          item.classList.remove("filter-opacity");
        }
      }
      this.$store.commit("clearFilteredData");
      this.$store.commit("clearFilterList");
      if (this.$store.getters.getFilteredData) {
        this.$store.commit("setShowFilteredData", false);
      }
    }
  }
};
</script>

<style scoped>
#year-description {
  letter-spacing: 1px;
}

.canciones-desktop-container {
  /* margin-top: 10vh; */
  /* width: 100vw; */
  background: url("/img/canciones/canciones-bkg-desktop.JPG") repeat;
  background-size: contain;
}

.canciones-filters {
  min-width: unset;
}

#canciones-container {
  max-height: 500px;
}

#title {
  font-size: 20px;
  border-bottom: 10px solid black;
  position: absolute;
  top: 0;
  width: 100%;
}
#title .title-text {
  display: inline-block;
  line-height: 1;
}

.year-title {
  font-size: 60px;
}

#year-description,
#canciones-info {
  overflow-y: auto;
}

/* @media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .gif-canciones {
    height: 90vh;
    align-items: flex-end;
    -webkit-align-items: flex-end;
  }

  .canciones-desktop-header {
    margin-top: 50vh;
  }

  .canciones-desktop-container {
    padding-bottom: 2vh;
  }

  .canciones-desktop-title-container {
    background-color: black;
    color: white;
    padding: 2vh 0 1vh 6vw;
    width: 35vw;
    height: 8vh;
    margin-left: 15vw;
  }

  .header-description-title {
    font-size: 4vh;
    letter-spacing: 6px;
  }

  .mi-lista {
    margin: 3vh 3vw;
    padding: 5px 0;
    border: solid 1px white;
    height: 6vh;
    width: 15vw;
  }

  .mi-lista-title {
    font-size: 2.5vh;
    letter-spacing: 6px;
  }

  .canciones-header-divider {
    background-color: black;
    padding: 1vh 0;
  }

  .canciones-desktop-info-container {
    padding-left: 8vh;
    margin-bottom: 5vh;
    max-width: 100vw;
    margin-top: -0.2vh;
  }

  .canciones-year-info {
    max-width: 50vw;
  }

  .year-slide {
    margin-left: 13vw;
    max-height: 12vh;
    margin-bottom: 5vh;
  }

  .year-title {
    font-size: 10vh;
    color: gray;
  }

  .prev-year,
  .next-year {
    color: gray;
    margin-top: 4.5vh;
  }

  .next-year {
    margin-left: 1vw;
    padding-left: 2px;
  }

  .canciones-year-description {
    margin: 0 5vw 3vh 5vw;
    max-width: 100vw;
  }

  .canciones-filters {
    margin-left: 2vw;
  }

  .sidebar-spacer {
    background-color: white;
    padding-left: 7vw;
    margin-left: -2vw;
  }

  .top-spacer-height {
    max-height: 3.5vh;
  }

  .bottom-spacer-height {
    margin-top: 25vh;
  }

  .lista-canciones-info {
    max-width: 40vw;
    margin-top: 3.5vh;
    margin-left: 29px;
  }

  .comments-container-canciones-desktop {
    background-color: white;
    margin: 7vh 8vh 0 8vh;
  }

  .mt-on-modal {
    margin-top: -100vh;
  }

  .favoritos-modal-open {
    margin-top: 1vh !important;
  }

  .favoritos-modal-open::-webkit-scrollbar {*/
/*display: none;*/
/*}
} */
</style>

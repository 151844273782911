<template>
  <div class="info-cancion-wrapper" :class="{'black--text': black, 'white--text': !black}">
    <div class="info-cancion-title">
      <p
        class="info-cancion-title band-name text-font text-uppercase font-italic"
        :class="[{'secundario': !text, 'width-auto':text}, {'black-text': non_filter}]"
      >
        {{ band }} /
        <span class="title-font cancion-year font-italic">{{ year }}</span>
      </p>
    </div>
    <div class="info-cancion-text" v-if="text">
      <p class="text-font text-left">{{ fact_description }}</p>
    </div>
    <div :class="{'no-text' : !text, 'info-cancion-actions' : text}">
      <v-btn flat icon class="ma-0" :class="{'black-text': non_filter}" @click="hearSong">
        <v-icon medium :color="black ? 'black' : 'white'">{{ play_music_icon }}</v-icon>
      </v-btn>
      <v-btn flat icon class="ma-0" :class="{'black-text': non_filter}" @click="goToYoutubeLink">
        <font-awesome-icon
          :icon="['fab', 'youtube']"
          style="font-size: 22px"
          :color="black ? 'black' : 'white'"
        />
      </v-btn>
    </div>
    <!--<div v-if="bottom_line" class="secundario-bottom-line"></div>-->
  </div>
</template>

<script>
import { Howl } from "howler";

export default {
  name: "CancionInfo",
  props: {
    black: Boolean,
    band: {
      type: String,
      default: ""
    },
    year: {
      type: String,
      default: ""
    },
    fact_description: {
      type: String,
      default: ""
    },
    song_title: String,
    songStartTime: Number,
    songEndTime: Number,
    bottom_line: Boolean,
    text: Boolean,
    non_filter: {
      typr: Boolean,
      default: false
    },
    song_id: Number,
    youtube: String,
  },
  data() {
    return {
      favorito_color: "",
      favorito: false,
      // play_music_icon: "volume_up",
      file: "/mp3/song.mp3",
      // play: false,
      sound: null,
      songId: null,
      utils: null
    };
  },
  computed: {
    // ...mapGetters([
    //   'playingSong'
    // ]),
    play() {
      return this.$store.getters.playingSong === this.song_id;
    },
    play_music_icon() {
      return this.play ? "stop" : "play_arrow";
    }
  },
  updated() {
    if (this.play) {
      this.sound.seek(this.songStartTime);
        this.sound.play();
    } else {
      this.sound.stop();
    }
    // if(this.playingSong != this.song_id) {
    //   console.log(this.song_id, "stop");
    // } else {
    //   console.log(this.song_id, "play");
    // }
  },
  mounted() {
    // let vm = this;
    // this.utils = {
    //   formatTime: function(secs) {
    //     let minutes = Math.floor(secs / 60) || 0;
    //     let seconds = secs - minutes * 60 || 0;
    //     return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    //   },
    //   updateTimeTracker: function() {
    //     let self = this;
    //     let seek = vm.sound.seek() || 0;
    //     // let currentTime = vm.utils.formatTime(Math.round(seek));
    //     // console.log((((seek / self.duration()) * 100) || 0) + '%');

    //     if (self.playing()) {
    //       if (Math.trunc(seek) != vm.songEndTime)
    //         requestAnimationFrame(vm.utils.updateTimeTracker.bind(self));
    //       else {
    //         vm.sound.stop(this.songId);
    //         vm.play = false;
    //       }
    //     }
    //   }
    // };

    // console.log(`${window.location.origin}/mp3/${this.song_id}.mp3`);
    this.sound = new Howl({
      src: [`/mp3/${this.song_id}.mp3`],
      html5: true,
      onend: () => {
        this.play = false;
      }
      // onplay: function() {
      //   requestAnimationFrame(vm.utils.updateTimeTracker.bind(this));
      // }
    });
  },
  methods: {
    hearSong() {
      // if (this.play_music_icon === "volume_off") {
      //   this.play_music_icon = "volume_up";
      //   this.playAudio();
      // } else {
      //   this.play_music_icon = "volume_off";
      // }
      this.playAudio();
    },
    playAudio() {
      if (!this.play) {
        // this.play = true;
        // this.sound.seek(this.songStartTime);
        // this.sound.play();
        this.$store.commit('playSong', this.song_id);
      } else {
        // this.play = false;
        // this.sound.stop();
        this.$store.commit('playSong', null);
      }
    },
    goToYoutubeLink() {
      //let routeData = this.$router.go({path: this.$store.getters.getYoutubeLink});
      window.open(this.youtube);
    }
  }
};
</script>

<style scoped>
.no-text {
  display: inline-block;
}

/* .secundario {
  width: 65vw;
} */

.width-auto {
  width: auto;
}

/* .secundario-bottom-line {
        height: 2vw;
        background-color: black!important;
        margin-top: 5vw;
    } */

/* .info-cancion-title {
        display: inline-block;
        float: left;
        margin-left: 1vw;
    } */

/* .info-cancion-text {
        clear: left;
        margin-left: 1vh;
        padding-top: 2vw;
    } */

/* .info-cancion-act-i {
        min-width: 5vw;
        border-radius: 0!important;
        color: white;
    } */

/* .info-cancion-actions {
        padding-bottom: 5vw;
    } */

/*.cancion-year {*/
/*margin-left: 1vw;*/
/*}*/

.text-left {
  text-align: left;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  /* .info-cancion-title, .info-cancion-actions {
            margin-left: 6px;
        } */

  /* .info-cancion-text {
            margin-left: 2vh;
            padding-top: 0;
        } */

  /* .info-cancion-wrapper {
            margin-left: 2vw;
        } */

  /* .info-cancion-actions {
            padding-bottom: 1vw;
        } */

  /* .info-cancion-act-i {
            min-width: auto !important;
            width: 3vw !important;
        } */

  /* .secundario-bottom-line {
            margin-top: 1vw;
            height: 8px;
            width: 37.8vw;
            margin-left: -2vw
        } */
}
</style>

<template>
  <v-container
    row
    class="lista-canciones-wrapper pa-0"
    :class="{'modal-open': $store.getters.showModal}"
  >
    <div class="lista-canciones-container">
      <v-layout
        id="lista-canciones-layout"
        class="lista-canciones-layout"
        v-touch="{right: viewNextListaCanciones}"
      >
        <v-flex class="lista-canciones">
          <ListadoCanciones
            view_year
            :titulos-principales="titulos_principales"
            :titulos-secundarios="titulos_secundarios"
          ></ListadoCanciones>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import ListadoCanciones from "../../components/Canciones/ListadoCanciones";

export default {
  name: "ListaCanciones",
  props: {
    current_year: {
      type: String,
      default: ""
    }
  },
  components: {
    ListadoCanciones
  },
  data() {
    return {
      next: 0,
      prev: -1,
      titulos_principales: null,
      titulos_secundarios: null
    };
  },
  // updated() {
  //   this.loadCurrentYear();
  // },
  mounted() {
    this.loadCurrentYear();
  },
  methods: {
    viewNextListaCanciones() {
      this.$router.replace({name: 'songs-home', hash: '#year-title-wrapper', params: {year: this.current_year}});
      // if (direction === 'right') {
      // this.$emit("vTouchRightCanciones", this.current_year);
      // }
    },
    loadCurrentYear() {
      this.$store.commit("songYear", this.current_year);
      this.$store.dispatch("spliceTitulosSecundarios").then(() => {
        this.titulos_principales = this.$store.getters.titulosPrincipales;
        this.titulos_secundarios = this.$store.getters.getTitulosSecundariosPartitioned;
        this.next = 1;
        this.prev = 0;
      });
    }
  }
};
</script>

<style>
/* .modal-open {
  overflow: -moz-scrollbars-none;
  position: fixed;
  margin-top: 1vh !important;
  overflow: hidden;
}

.modal-open::-webkit-scrollbar {
  display: none;
} */

/* .mi-lista {
  background-color: black;
  color: white;
  padding: 2vw;
  margin: 5vw 0;
  text-align: center;
}

.mi-lista-title {
  font-size: 4.5vw;
  letter-spacing: 6px;
} */

.lista-canciones-wrapper {
  /* width: 100vw;
  height: auto;
  overflow: hidden;
  padding: 0 !important;
  margin-top: 8vh; */
  /* background-image: url("/img/canciones/lista-canciones-bkg.jpeg");
  background-size: cover; */
}

/* .lista-canciones {
  max-width: 85vw;
  overflow: hidden;
} */

/* @media only screen and (min-device-width: 0) and (max-device-width: 360px) {
  .lista-canciones-scroll {
    width: 81vw;
  }
}

@media only screen and (min-device-width: 0) and (max-device-width: 320px) {
  .lista-canciones-wrapper {
    margin-top: 20vw;
  }
}

@media (min-width: 400px) and (max-width: 740px) {
  .lista-canciones {
    max-width: 92vw !important;
  }
} */
</style>

<template>
  <cmp-layout :component="component">
    <template v-slot:headerImage v-if="show_year">
      <v-img src="/img/canciones/PORTADA-CANCIONES-PHONE.gif"></v-img>
      <!-- <v-responsive>
      <video preload="yes" autoplay loop muted playsinline class="main-video">
        <source src="/img/canciones/PORTADA-CANCIONES-PHONE.webm" type="video/webm" />
        <source src="/img/canciones/PORTADA-CANCIONES-PHONE.mp4" type="video/mp4" />
      </video>
      </v-responsive> -->
    </template>
    <template v-slot:component>
      <router-view></router-view>
    </template>
  </cmp-layout>
</template>
<script>
import ComponentsLayout from "../ComponentsLayout";
// import ListaCanciones from "../../views/Canciones/ListaCanciones";

export default {
  name: "",
  components: {
    "cmp-layout": ComponentsLayout,
    // "lista-canciones": ListaCanciones,
  },
  props: {
    year_text: Array,
    show_year: Boolean,
  },
  mounted() {
    this.viewCurrentYearDescription("2010");
  },
  updated() {
    /* if (!this.showListadoCanciones) {

    } */ /* else {
      this.$vuetify.goTo("#song-list", { duration: 0 });
    } */
  },
  data: () => ({
    // year: "2010",
    // current_description: "2010",
    // description: "",
    next_el: 0,
    last_el: -1,
    showListadoCanciones: false
    // years: [
    //   "2010",
    //   "2011",
    //   "2012",
    //   "2013",
    //   "2014",
    //   "2015",
    //   "2016",
    //   "2017",
    //   "2018"
    // ]
  }),
  computed: {
    component() {
      return this.show_year ? '' : 'canciones';
    },
  },
  methods: {
    viewCurrentYearDescription(year) {
      let counter = 0;

      for (let el of this.year_text) {
        if (el.year === year) {
          this.current_description = year;
          this.description = el.text;
          this.year = year;
          counter++;
          break;
        }
        counter++;
      }
      this.next_el = counter;
      this.last_el = counter - 1;
    },
    increaseYear() {
      if (this.canciones_main_description) {
        this.canciones_main_description = false;
      }
      if (this.next_el < this.year_text.length) {
        let next = this.year_text[this.next_el];
        this.year = next.year;
        this.description = next.text;
        this.current_description = this.year;
        this.next_el++;
      }
    },
    decreaseYear() {
      // let el = this.$el.getElementsByClassName("canciones-home-text-wrapper").item(0);
      // if (direction === 'left') {

      // }
      // else if (direction === 'right') {
      this.last_el = this.next_el - 2;
      this.next_el--;
      if (this.last_el >= 0 && this.last_el < this.year_text.length) {
        let prev = this.year_text[this.last_el];
        this.year = prev.year;
        this.description = prev.text;
        this.current_description = this.year;
      }
      // }
    },
    // viewListadoCanciones() {
    //   this.$store.commit("setViewYear", true);
    //   this.showListadoCanciones = true;
    //   this.component = "canciones";
    // },
    // setShowListadoCanciones(currentYear) {
    //   this.year = currentYear;
    //   this.viewCurrentYearDescription(this.year);
    //   this.showListadoCanciones = false;
    //   this.component = "";
    // }
  }
};
</script>
<style lang="scss" scoped>

// #year-title-wrapper {
//     margin-top: 10vh;
//     padding-left: 5vw;
//     align-items: center;
// }

// .more-info-canciones {
//   max-width: 40px;
//   height: 40px;
//   background-color: black;
//   margin-top: auto;
//   padding: 6px 4px 0;
//   text-align: center;
// }
</style>

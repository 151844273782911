<template>
  <div class="data-filtered">
    <div v-for="(filtro, i) in filtered_data" :key="i">
      <div
        class="filtro-titulos-canciones-wrapper"
        v-for="(cancion, e) in filtro.response"
        :key="e"
        :class="{'info-cancion' : cancion.show_info}"
      >
        <div
          class="filtro-titulos-principles-title mt-3 pl-4 pb-4"
          :class="[setFactBkgColor(filtro.fact), {'pt-show-info': cancion.show_info}]"
          :id="cancion.data.id"
        >
          <v-layout row>
            <v-flex grow class="pt-4">
              <v-btn
                flat
                :light="isBlack(filtro.fact)"
                :dark="!isBlack(filtro.fact)"
                @click="$emit('on-show-info', cancion)"
                class="pa-0 mx-0 title-font titulos-principales text-uppercase letter-space"
              >{{ cancion.data.songtitle }}</v-btn>
            </v-flex>
            <v-btn
              icon
              v-if="cancion.show_info"
              class="close-filtro-info-btn"
              :light="isBlack(filtro.fact)"
              :dark="!isBlack(filtro.fact)"
              @click="closeFiltroBoxInfo(cancion)"
            >
              <v-icon medium>close</v-icon>
            </v-btn>
          </v-layout>
          <v-expand-transition>
            <CancionInfo
              class="info-canciones-ml pr-4"
              :black="isBlack(filtro.fact) && cancion.show_info"
              :band="cancion.data.band"
              :year="cancion.data.year"
              text
              :fact_description="cancion.data.fact_description"
              v-if="cancion.show_info"
              :song_id="cancion.data.id"
              :youtube="cancion.data.youtube"
            ></CancionInfo>
          </v-expand-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CancionInfo from "../Canciones/CancionInfo";

export default {
  name: "CancionesFiltros",
  props: {
    filtered_data: Array
  },
  components: {
    CancionInfo
  },
  methods: {
    isBlack(fact) {
      const val = this.setFactBkgColor(fact);
      return val === "lenguaje-bkg" || val === "polemica-bkg";
    },
    setFactBkgColor(fact) {
      switch (fact) {
        case "Música":
          return "musica-bkg";
        case "Baile":
          return "baile-bkg";
        case "Polémica":
          return "polemica-bkg";
        case "Social":
          return "social-bkg";
        case "Lenguaje":
          return "lenguaje-bkg";
        default:
          return "non-filter";
      }
    },
    closeFiltroBoxInfo(data) {
      data.show_info = false;
    }
  }
};
</script>

<style scoped>
/* .filtro-titulos-principles-title {
  margin: 3vw 0;
  padding: 0 5vw;
}

.close-filtro-info-btn {
  left: 60vw;
} */

/*.close-filtro-btn-black {*/
/*color: black!important;*/
/*}*/

/*.close-filtro-info-btn {*/
/*color: white!important;*/
/*}*/

/* @media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .filtro-titulos-principles-title {
    margin: 2vh 0;
    padding: 1vh 4.5vw;
  }

  .close-filtro-info-btn {
    left: 31.4vw;
  }

  .info-canciones-ml {
    margin-left: -3vw;
  }
} */
</style>

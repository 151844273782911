<template>
  <div class="white black--text lista-canciones-year-title lista-canciones-box">
    <v-layout column>
      <v-layout align-center class="year-lista-canciones" :class="{'top-title-height': is_filter_data}">
        <v-btn color="white" @click="goBack" icon v-if="$store.getters.getViewYear">
          <v-icon>arrow_back_ios</v-icon>
        </v-btn>
        <div
          class="title-font lista-canciones-top-title"
          v-if="$store.getters.getViewYear"
        >{{ $store.getters.songYear }}</div>
        <v-spacer class="black-spacer mt-4" :class="{'mb-4': !is_filter_data}"></v-spacer>
      </v-layout>
      <div v-if="is_filter_data">
        <v-flex
          class="filtros-info text-xs-right"
          v-for="(fact, n) in $store.getters.getFilterList"
          :key="n"
        >
          <v-layout column align-end class="filtros-header-container">
            <!-- <v-layout column class="filtro-info-container"> -->
            <v-flex class="filtro-title pr-4">
              <span
                class="title-font text-uppercase fact-title"
                :style="{color: fact.color}"
              >{{ fact.fact }}</span>
            </v-flex>
            <v-flex class="filtro-description pr-4">
              <p class="text-font font-italic filtro-text">{{ factDescription(fact.fact) }}</p>
              <p v-if="fact.no_data" class="text-font font-italic filtro-text">{{ fact.msg }}</p>
            </v-flex>
            <!-- </v-layout> -->
            <v-spacer
              align-self-end
              class="black-spacer"
              v-if="n < $store.getters.getFilterList.length - 1"
            ></v-spacer>
          </v-layout>
        </v-flex>
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "TopHeaderListadoCanciones",
  props: {
    year: {
      type: String,
      default: ""
    },
    is_filter_data: Boolean,
    view_year: Boolean
  },
  data() {
    return {
      fact_description: [
        {
          fact: "Música",
          text:
            "Canciones que marcaron hechos importantes para la música cubana."
        },
        {
          fact: "Baile",
          text:
            "Canciones cuyos bailes fueron significativos, seguro te acordarás."
        },
        {
          fact: "Lenguaje",
          text: "Canciones con frases que se hicieron populares"
        },
        {
          fact: "Polémica",
          text: "Canciones que desataron conflictos, de varios tipos."
        },
        {
          fact: "Social",
          text:
            "Canciones que impactaron en los modos de vida de las personas, ya verás por qué."
        }
      ],
      text: ""
    };
  },
  methods: {
    factDescription(fact) {
      for (let val of this.fact_description) {
        if (fact === val.fact) return val.text;
      }
    },
    goBack() {
      this.$router.replace({
        name: "songs-home",
        hash: "#year-title-wrapper",
        params: { year: this.$store.getters.songYear }
      });
    }
    // closeFilters() {
    //   this.$store.commit("setShowFilteredData", false);
    //   this.$store.commit("clearFilteredData");
    //   this.$store.commit("clearFilterList");
    // }
  }
};
</script>

<style scoped>
/* .lista-canciones-year-title {
        padding-left: 5vw;
    } */

.lista-canciones-top-title {
  font-size: 37px;
  letter-spacing: 4px !important;
}

.black-spacer {
  background-color: black;
  height: 6px;
  /* padding: 0!important; */
  width: 50%;
  float: right;
  /* margin-top: 7vw; */
}

/* .filter-black-spacer {
        margin-top: -1vh!important;
        margin-left: 45vw;
        margin-bottom: 5px;
    } */

/* .filtro-info-container {
        top: 0;
    } */

/* .filtro-title {
        margin-left: 45vw;
        margin-top: 2px;
    } */

.fact-title {
  font-size: 20px;
  font-family: Andada;
}

.filtro-text {
  font-size: 12px;
  color: black;
  /* text-align: end; */
}

/* .filtro-description {
        margin-right: 3vw;
    } */

/* .close-filtros {
  margin-top: 20px;
  width: 25px;
  height: 25px;
} */

/* @media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
        .lista-canciones-year-title {
            padding-top: 3vh;
        }
    }

    @media only screen and (min-device-width: 0px) and (max-device-width: 320px) {
        .lista-canciones-year-title {
            padding-top: 3vh;
        }
    } */

/* .top-title-height {
        height: 5vh;
    } */

.transparent-spacer {
  height: 0 !important;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  /* .lista-canciones-top-title {
    font-size: 10vh;
  } */

  /* .black-spacer {
            width: 22vw;
            margin-top: 3.5vw!important;
            height: 2vh;
        } */

  /* .margin-spacer {
            margin: 4vh 0!important;
        } */

  /* .filtros-info-container {
    margin-top: 1.5vh;
    margin-bottom: 2vh;
  } */

  /* .filtros-header-container {
            max-width: 37vw;
        } */

  /* .filtro-title {
            margin-left: 15vw;
            margin-top: 0;
        } */

  /* .fact-title {
            font-size: 6vh;
        } */

  /* .filter-black-spacer {
            margin-left: 15vw;
            margin-top: -1.5vh!important;
            margin-bottom: 0;
        } */

  /* .filtro-text {
            margin-bottom: 21px;
        } */
}
</style>

<template>
  <v-layout column data-view>
    <canciones-desktop :data="year_text" class="hidden-xs-only"></canciones-desktop>
    <canciones-mobile class="hidden-sm-and-up" :year_text="year_text" :show_year="showYear" />
    <!-- <ComentariosModal v-if="$store.getters.showModal"><ComentariosForm></ComentariosForm></ComentariosModal> -->
    <v-layout row wrap class="pa-4">
      <v-flex xs12 sm6 class="canciones-lista-comentarios">
        <ListaComentarios />
      </v-flex>
      <v-flex xs12 sm6 class="pt-4">
        <ComentariosForm />
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
// import ComentariosModal from '../../components/Comentarios/ComentariosModal'
import ComentariosForm from "../../components/Comentarios/ComentariosForm";
import ListaComentarios from "../../components/Comentarios/ListaComentarios";
import CancionesMobile from "../../components/Canciones/CancionesMobile";
import CancionesDesktop from "./CancionesDesktop";

export default {
  name: "Canciones",
  head: {
    title: {
      inner: "Flow y poder",
      separator: " - ",
      complement: "Canciones + Populares"
    },
    meta: [
      { p: "og:title", c: "Flow y poder - Canciones + Populares" },
      {
        p: "og:description",
        c:
          "Recogemos de manera sintetizada los éxitos, controversias y momentos más significativos de este género urbano en Cuba en los últimos ocho años."
      },
      {
        n: "description",
        c:
          "Recogemos de manera sintetizada los éxitos, controversias y momentos más significativos de este género urbano en Cuba en los últimos ocho años."
      },
      { p: "og:url", c: "https://reggaeton.eltoque.com/canciones-populares" },
      { p: "og:image", c: "https://reggaeton.eltoque.com/img/canciones/portada.jpg" }
    ]
  },
  fetch({ store }) {
    if (store.getters.loadedSongs) return;
    return store.dispatch("GET_CANCIONES");
  },
  components: {
    // ComentariosModal,
    ComentariosForm,
    CancionesDesktop,
    CancionesMobile,
    ListaComentarios
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit("setShowToolbarTitle", true);
      vm.$store.commit("setShowComponentsInFooter", true);
      vm.$store.commit("setViewCancionesBox", false);
      vm.$store.commit("setViewArtistasBox", true);
      vm.$store.commit("setViewLasBalasBox", true);
      vm.$store.commit("setViewJuegoBox", true);
      next();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.showYear = to.name === "songs-home";
    next();
  },
  data() {
    return {
      showYear: true
    };
  },
  computed: {
    year_text() {
      return this.$store.getters.getYearData;
    }
  }
};
</script>

<style scoped>
/* .container-component-canciones {
  margin: 0;
  max-width: 100%;
  padding: 0;
} */

/* .canciones-home-wrapper {
  max-height: 42vh;
} */

/* .visible-on-desktop {
  display: none;
} */

/* .visible-on-mobile {
  display: block;
} */

/* .year-text {
  margin-right: -3vw;
  margin-left: 2vw;
} */

/* @media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .visible-on-mobile {
    display: none;
  }

  .visible-on-desktop {
    display: block;
  }

  .toolbar-modal-open {
    z-index: 9999;
    position: sticky;
  }
} */
</style>
